<script>
import PageHeader from "@/components/PageHeader.vue";
import { PROVINCE_CODES} from "@/utils/config";
import pcaCode from "@/utils/pca-code";
import {
  developerOptions,
  distributorCreate,
  distributorInfo,
  distributorUpdate,
} from "@/api/common";

export default {
  name: "EditDealer",
  components: {PageHeader},
  data(){
    return {
      id: null,
      pca_options: pcaCode,
      developer_options: [],
      disPageForm: {
        developersId:'',
        distributorCode: '',
        name: '',
        unifiedCreditCode: '',
        head: '',
        idNo: '',
        phone: '',
        province: '',
        city: '',
        area: '',
        areas: [],
        address: '',
      },
      disRules: {
        developersId: [
          {required: true, message: '请选择开发商', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请输入名称', trigger: 'blur'},
        ],
        unifiedCreditCode: [
          {required: true, message: '请输入统一信用代码', trigger: 'blur'},
        ],
        head: [
          {required: true, message: '请输入负责人', trigger: 'blur'},
        ],
        idNo: [
          {required: true, message: '请输入身份证号', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入电话', trigger: 'blur'},
        ],
        areas: [
          {required: true, message: '请选择地址', trigger: 'blur'},
        ],
        address: [
          {required: true, message: '请输入详细地址', trigger: 'blur'},
        ],
      },
      page_form_loading: false,
    }
  },
  mounted() {
    this.id = this.$route.params.id
    if (this.id) {
      this.getInfo()
    }
    this.getDeveloperOptions()
  },
  methods: {
    getDeveloperOptions() {
      let that = this
      developerOptions().then(res => {
        that.developer_options = res
      })
    },
    getInfo(){
      distributorInfo(this.id).then(item => {
        this.disPageForm = {
          id: item.id,
          developersId: item.developersId,
          distributorCode: item.distributorCode,
          name: item.name,
          unifiedCreditCode: item.unifiedCreditCode,
          head: item.head,
          idNo: item.idNo,
          phone: item.phone,
          province: item.province,
          city: item.city,
          area: item.area,
          areas: [item.province, item.city, item.area],
          address: item.address,
          version: item.version,
      }
      })
    },
    areaChange(val, formName) {
      this[formName].province = val[0]
      this[formName].city = val[1]
      this[formName].area = val[2]
      let company = PROVINCE_CODES.find(item => {
        return item.province == val[0]
      })
      if (company) {
        this[formName].provinceCode = company.id
      }
    },
    distributorSubmit(ref) {
      let that = this
      that.$refs[ref].validate((valid) => {
        if (!valid) return false
        that.page_form_loading = true
        let func
        if (this.id) {
          func = distributorUpdate
        } else {
          func = distributorCreate
        }
        func(that.disPageForm).then(() => {
          that.page_form_loading = false
          that.$message.success('提交成功')
          that.distributor_dialog_visible = false
          setTimeout(() => {
            that.$router.replace('/admin/organization')
          }, 500)
        }).catch(() => {
          that.page_form_loading = false
        })
      })
    },
    cancel(){
      this.$router.replace('/admin/organization')
    }
  }
}
</script>

<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-header">
          <span>{{ $route.meta.title }}</span>
        </div>
        <div class="page-card-body">
          <el-form :model="disPageForm" :rules="disRules"
                   label-width="120px" ref="disPageForm"
                   inline label-position="top"
                   v-loading="page_form_loading">
            <el-row>
              <el-col :span="5">
                <el-form-item label="开发商" prop="developersId" style="width: 100%;">
                  <el-select v-model="disPageForm.developersId" placeholder="开发商" style="width: 100%;">
                    <el-option v-for="item in developer_options" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item label="经销商code" style="width: 100%;">
                  <el-input placeholder="系统自动生成" v-model="disPageForm.distributorCode" maxlength="20" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item label="经销商名称" prop="name" style="width: 100%;">
                  <el-input placeholder="经销商名称" v-model="disPageForm.name" maxlength="20"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item style="width: 100%;" label="统一信用代码" prop="unifiedCreditCode">
                  <el-input placeholder="统一信用代码" v-model="disPageForm.unifiedCreditCode" maxlength="20"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5">
                <el-form-item style="width: 100%;" label="负责人" prop="head">
                  <el-input placeholder="负责人" v-model="disPageForm.head" maxlength="20"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item style="width: 100%;" label="负责人身份证" prop="idNo">
                  <el-input placeholder="负责人身份证" v-model="disPageForm.idNo" maxlength="20"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item style="width: 100%;" label="电话" prop="phone">
                  <el-input placeholder="电话" v-model="disPageForm.phone" maxlength="11"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item style="width: 100%;" label="地址" prop="areas">
                  <el-cascader style="width: 100%;" ref="areaCascaderRef" v-model="disPageForm.areas" :options="pca_options"
                               @change="areaChange($event, 'disPageForm')"
                               :props="{ checkStrictly: false , label: 'name', value: 'name' ,children: 'children' }"></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5">
                <el-form-item label="详细地址" prop="address" style="width: 100%;">
                  <el-input v-model="disPageForm.address" placeholder="详细地址"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">

              </el-col>
              <el-col :span="5" :offset="1">

              </el-col>
              <el-col :span="5" :offset="1">

              </el-col>
            </el-row>

            <div class="dialog-footer">
              <el-button @click="cancel">取 消</el-button>
              <el-button @click="distributorSubmit('disPageForm')" type="primary">确 定</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.page-card-body {
  padding: 20px;

  .table-action-item {
    display: inline-block;
    cursor: pointer;
    color: $--color-primary;

    & + .table-action-item {
      margin-left: 10px;
    }
  }
}
</style>